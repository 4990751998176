class TimeRecord {
  start: number = 0
  end: number = 0
  total: number = 0
  label: string = ""
  count: number = 0

  constructor(label:string) {
    this.label = label
  }

  public s = () => {
    this.start = performance.now()
    this.count++
  }

  public e = () => {
    this.end = performance.now()
    this.total += (this.end - this.start)

    return this
  }

  public toString() {
    return `${this.label} | ${this.total} ms | ${this.count}`
  }

}

export class TimerService {
  records: { [label:string]: TimeRecord} = {}
  public start = (label:string) => {
    if (this.records[label] === undefined) {
      this.records[label] = new TimeRecord(label)
    }
    this.records[label].s()
  }

  public end = (label:string, log:boolean=false) => {
    if (this.records[label] === undefined) {  return; }
    let record = this.records[label].e()
    log && console.log(`${record}`)
  }

  public toString = () => {
    let s = ""
    for (let [label, record] of Object.entries(this.records)) {
      s += `${record} \n`
    }
    return s
  }
}