import { Action, createAction, props } from "@ngrx/store";
import { User } from "../models/user.model";

export const userDetails = createAction(
    '[User details] retrieve loggedIn user details',props<{user:ReadonlyArray<User> }>()
)
export const addUpdateAction = createAction(
    '[User Add or Update]',props<{addUpdate:boolean}>()
)
export const userInfo = createAction(
    '[User info] user info',props<{userInfoDetails:any }>()
)