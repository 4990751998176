import { ActionReducer } from '@ngrx/store';
import { LOGOUT } from '../actions/logout.action'

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === LOGOUT) {
            // state.showLoader = false
            state = [];
        }
        return reducer(state, action);
    };
}
