import { createSelector, createFeatureSelector } from "@ngrx/store";
import { User } from "../models/user.model";

export const user = createFeatureSelector<User>('user')
export const addUpdate = createFeatureSelector('addUpdate')
export const userInfo = createFeatureSelector('userInfo')

export const selectUserCollection = createSelector(
    user,
    (user) => user
)
export const selectAddUpdateCollection = createSelector(
    addUpdate,
    (addUpdate) => addUpdate
)  
export const selectUserInfo = createSelector(
    userInfo,
    (userInfo) => userInfo
)
 