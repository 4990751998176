import {BehaviorSubject, Observable} from "rxjs";
import { Injectable } from '@angular/core';
import { Point } from "@angular/cdk/drag-drop";
import {GreenImageData, SprinklerProfile, Entity, Rectangle, IgpPlayground, SaveSprinklerModel, SaveSprinklerProfile} from "./custom-types";
import { ImageDataService } from "./image-data-service";
import {TimerService} from "../services/timer.service";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
    providedIn: 'root'
})

export class SprinklerBookService {

    canvasContainer: HTMLElement;
    sprinklerProfiles: SprinklerProfile[] = [];
    depths: number[];
    copyrightYear: string = "";
    widthRatio: number = 1;
    heightRatio: number = 1;
    resizeWidth: number = 825;
    resizeHeight: number = 600;
    isShrinked: boolean = false;
    private _progress: BehaviorSubject<number> = new BehaviorSubject(0);
    public progressUpdates: Observable<number> = this._progress.asObservable()

    private set progress(value:number) {
        this._progress.next(value);
    }

    private get progress() {
        return this._progress.value;
    }

    constructor(
        private imageDataService: ImageDataService
    ) {

    }

    reset() {
        this.sprinklerProfiles = [];
        this.depths = [];
        this.copyrightYear = "";
        this.widthRatio = 1;
        this.heightRatio = 1;
        this.resizeWidth = 825;
        this.resizeHeight = 600;
        this.isShrinked = false;
        this.progress = 0
    }

    async sprinklerBook(canvasContainer:HTMLElement, sprinklerFiles: File[], depthFile: File, copyrightYear: string) : Promise<SprinklerProfile[]> {
        this.progress = 0
        this.canvasContainer = canvasContainer
        this.copyrightYear = copyrightYear
        console.time("LOAD-TIME")
        this.sprinklerProfiles = await this.loadFiles(sprinklerFiles, depthFile, 100)
        console.timeEnd("LOAD-TIME")
        this.progress = 100
        return this.sprinklerProfiles
    }

    async loadFiles(sprinklerFiles: File[], depthFile: File, progressCap: number = 100) {
        console.time("DEPTH-TIME")
        this.depths = await this.readDepthFile(depthFile)
        console.timeEnd("DEPTH-TIME")
        let sprinklerProfiles:SprinklerProfile[] = []
        sprinklerFiles = sprinklerFiles.filter(f => this.isValidSprinklerFile(f))
        console.time("SPRINKLER-PROFILES-TIME")
        const progressPerFile = (progressCap)/Math.max(sprinklerFiles.length, 1)

        for (let i=0; i< sprinklerFiles.length; i++) {
            let profile = await this.processSprinklerFile(sprinklerFiles[i], this.depths[i], progressPerFile, `${i}`)
            sprinklerProfiles.push(profile)
        }

        // sprinklerProfiles = await Promise.all(sprinklerFiles.map((f, i) => {
        //     return this.processSprinklerFile(sprinklerFiles[i], this.depths[i], progressPerFile, `${i}`)
        // }))
        this.progress = 100

        // for(let i=0; i<sprinklerFiles.length; i++) {
        //     console.log(`************--${i + 1} - START--************`)
        //     let sprinklerProfile = await this.processSprinklerFile(sprinklerFiles[i], this.depths[i], progressPerFile)
        //     sprinklerProfiles.push(sprinklerProfile)
        //     // this.progress += progressPerFile
        //     console.log(`************--${i + 1} - END--************`)
        // }
        console.timeEnd("SPRINKLER-PROFILES-TIME")
        return sprinklerProfiles
    }

    async processSprinklerFile(file:File, depth:number, progressPerFile:number, id:string) : Promise<SprinklerProfile> {
        this.progress += progressPerFile * 20/100
        let playground = this.newPlayground()  // temporary canvas to do calculations and create images
        console.time(`IMG${id}-`+"READ-SPRINKLER-TIME")
        // read ImageData from uploaded file
        let image:ImageData = await this.readSprinklerFile(file, playground)
        this.progress += progressPerFile * 10/100
        console.timeEnd(`IMG${id}-`+"READ-SPRINKLER-TIME")
        // console.time(`IMG${id}-`+"GREEN-IMAGE-TIME")
        // // copying ImageData to GreenImageData. top/bottom/left/right are empty
        // let data:GreenImageData = this.imageDataService.greenImage(image)
        // this.progress += progressPerFile * 10/100
        // console.timeEnd(`IMG${id}-`+"GREEN-IMAGE-TIME")
        // console.time(`IMG${id}-`+"BOUNDS-TIME")
        // // set b/t/l/r points for the GreenImageData
        // this.getBounds(data)
        // this.progress += progressPerFile * 20/100
        // console.timeEnd(`IMG${id}-`+"BOUNDS-TIME")
        console.time(`IMG${id}-`+"SINGLE-PROFILE-TIME")
        let sprinklerProfile = await this.getSprinklerProfile(image, depth, this.copyrightYear, playground, id)
        sprinklerProfile.originalFileName = file.name.split('.').slice(0, -1).join('.')
        this.progress += progressPerFile * 70/100
        console.timeEnd(`IMG${id}-`+"SINGLE-PROFILE-TIME")
        return sprinklerProfile
    }

    async readDepthFile(depthFile:File) : Promise<any> {
        //to check for csv reader packages
        return new Promise((resolve, reject) => {
            let csvReader = new FileReader()
            csvReader.readAsText(depthFile)
            csvReader.onload = () => {
                let content = csvReader.result as string
                let csvRows = content?.split('\r\n')
                let csvData = csvRows.map((row) => {
                    if(parseInt(row.split(',')[0])){
                        return parseInt(row.split(',')[0])
                    }else{
                        console.warn(`${row.split(',')[0]} is not a number`);
                        return 0;
                    }
                })

                console.log(csvData, '***csv-depth')
                resolve(csvData)
            }
        })
    }

    async readSprinklerFile(sprinklerFile: File, playground:IgpPlayground) {
        let imageData = await this.getImageData(sprinklerFile, playground)
        return imageData
    }

    newPlayground():IgpPlayground {
        return {
            canvas: document.createElement('canvas'),
            image: document.createElement("img")
        }
    }

    async getImageData(imgFile: File, playground:IgpPlayground) : Promise<ImageData> {
        return new Promise((resolve, reject) =>{
            let imgReader = new FileReader();
            imgReader.readAsDataURL(imgFile)
            imgReader.onload = () => {
                let img = playground.image
                let canvas = playground.canvas
                img.src = imgReader.result as string
                img.onload = () => {
                    let imgData: ImageData
                    canvas.width = img.width
                    canvas.height = img.height
                    let ctx = <CanvasRenderingContext2D>canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0)
                    imgData = ctx.getImageData(0, 0, img.width, img.height)
                    resolve(imgData)
                }
            }
        })
    }

    isValidSprinklerFile(file: File): boolean {
        let fileName = file.name
        return (fileName.endsWith(".png")
          || fileName.endsWith(".jpeg")
          || fileName.endsWith(".jpg")
          || fileName.endsWith(".bmp")
        )
    }

    /**
     * Detect the contour line and calculate image edges(t/b/l/r)
     * @param {GreenImageData} data
     */
    getBounds(data: GreenImageData) : GreenImageData {
        let e: Entity = {} as Entity
        // start from center of the image and move downward in a straight line, looking for dark pixel
        let x = Math.round(data.width/2)
        for(let y = Math.round(data.height/2); y < data.height; y++) {
            let c = this.imageDataService.getColor(data, x, y)
            if(c.A != 0 && c.R < 100) {
                let point: Point = { x, y }
                e = this.getNewEntity(point, data, true)
                break;
            }
        }
        data.bottom = e.bottom
        data.top = e.top
        data.right = e.right
        data.left = e.left

        return data
    }

    getNewEntity(point: Point, image: GreenImageData, entire: boolean) : Entity {
        let entity: Entity = {
            initialPoint: point,
            entityArray: this.getEntityArray(image),
            count: 0,
            width: 0,
            height: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
        if(!entire) {
            entity = this.getContour(entity, image)
        }
        else {
            entity.entityArray = this.getEntireContour(entity.entityArray, image, point)
        }
        entity = this.getDimensions(entity)

        return entity
    }

    getNewEntityUsingContour(point: Point, image: GreenImageData, contour: Entity, entity:Entity, ts:TimerService) : Entity {
        // ts.start('new-entity-array')
        // ts.end('new-entity-array')
        ts.start('contour-with-contour')
        entity.initialPoint = point
        entity.entityArray = this.getEntireContourUsingContour(entity.entityArray, image, point, contour)
        ts.end('contour-with-contour')

        ts.start('entity-get-dimensions')
        entity = this.getDimensions(entity)
        ts.end('entity-get-dimensions')

        return entity
    }

    getEntityArray(data: GreenImageData) : Array<Uint8Array> {
        // return Array.from(
        //   {length: data.width},
        //   () => (new Array(data.height).fill(false))
        // )
        return Array.from(
          {length: data.width},
          () => (Uint8Array.from({length: data.height}))
        )
    }

    getContour(entity: Entity, image: GreenImageData) : Entity {
        let entityArray = entity.entityArray
        let done = false
        let operationPerformed = false
        let steppedBack = false
        let prevX = entity.initialPoint.x
        let prevY = entity.initialPoint.y
        let currX = entity.initialPoint.x
        let currY = entity.initialPoint.y
        let igX = -1
        let igY = -1

        entityArray[currX][currY] = 1

        while(!done) {
            if(!steppedBack) {
                if(currX + 1 < image.width) {
                    if(!operationPerformed && !entityArray[currX + 1][currY]) {
                        if(this.isTransparentPixel(currX + 1, currY, image)) {
                            entityArray[currX + 1][currY] = 1
                            prevX = currX
                            prevY = currY
                            currX++
                            operationPerformed = true
                        }
                    }
                }
                if(currY - 1 > 0) {
                    if(!operationPerformed && !entityArray[currX][currY - 1]) {
                        if(this.isTransparentPixel(currX, currY - 1, image)) {
                            entityArray[currX][currY - 1] = 1
                            prevX = currX
                            prevY = currY
                            currY--
                            operationPerformed = true
                        }
                    }
                }
                if(currX - 1 > 0) {
                    if(!operationPerformed && !entityArray[currX - 1][currY]) {
                        if(this.isTransparentPixel(currX - 1, currY, image)) {
                            entityArray[currX - 1][currY] = 1
                            prevX = currX
                            prevY = currY
                            currX--
                            operationPerformed = true
                        }
                    }
                }
                if(currY + 1 < image.height) {
                    if(!operationPerformed && !entityArray[currX][currY + 1]) {
                        if(this.isTransparentPixel(currX, currY + 1, image)) {
                            entityArray[currX][currY + 1] = 1
                            prevX = currX
                            prevY = currY
                            currY++
                            operationPerformed = true
                        }
                    }
                }
                if(!operationPerformed) {
                    igX = currX
                    igY = currY
                    currX = prevX
                    currY = prevY
                    steppedBack = true
                }
                else if(!operationPerformed) {
                    done = true
                } //Having confusion with above lines, need to check
                else {
                    operationPerformed = false
                }
            }
            else {
                if(currX + 1 < image.width && !(igX == currX + 1 && igY == currY)) {
                    if(!operationPerformed && !entityArray[currX + 1][currY]) {
                        if(this.isTransparentPixel(currX + 1, currY, image)) {
                            entityArray[currX + 1][currY] = 1
                            prevX = currX
                            prevY = currY
                            currX++
                            operationPerformed = true
                        }
                    }
                }
                if(currY - 1 > 0 && !(igX == currX && igY == currY - 1)) {
                    if(!operationPerformed && !entityArray[currX][currY - 1]) {
                        if(this.isTransparentPixel(currX, currY - 1, image)) {
                            entityArray[currX][currY - 1] = 1
                            prevX = currX
                            prevY = currY
                            currY--
                            operationPerformed = true
                        }
                    }
                }
                if(currX - 1 > 0 && !(igX == currX - 1 && igY == currY)) {
                    if(!operationPerformed && !entityArray[currX - 1][currY]) {
                        if(this.isTransparentPixel(currX - 1, currY, image)) {
                            entityArray[currX - 1][currY] = 1
                            prevX = currX
                            prevY = currY
                            currX--
                            operationPerformed = true
                        }
                    }
                }
                if(currY + 1 < image.height && !(igX == currX && igY == currY + 1)) {
                    if(!operationPerformed && !entityArray[currX][currY + 1]) {
                        if(this.isTransparentPixel(currX, currY + 1, image)) {
                            entityArray[currX][currY + 1] = 1
                            prevX = currX
                            prevY = currY
                            currY++
                            operationPerformed = true
                        }
                    }
                }
                if(!operationPerformed) {
                    done = true
                }
                else {
                    operationPerformed = false
                    steppedBack = false
                }
            }
        }
        return entity
    }

    /**
     * Detects all connected black pixels
     * * set `true` on the pixels positions to all the black pixels connected to the given point
     * used for detecting contour line and sprinkler entities
     * @param {Array<Uint8Array>} entityArray - a 2d array with the original image dimensions all places filled with `false`
     * @param {GreenImageData} image
     * @param point - A start point on the entity (Ex: bottom middle point of contour)
     */
    getEntireContour(entityArray: Array<Uint8Array>, image: GreenImageData, point: Point) : Array<Uint8Array> {
        let pointList: Point[] = []
        pointList.push(point)

        while(pointList.length > 0) {
            for(let i = 0; i < pointList.length; i++) {
                let {x, y} = pointList[i]
                let c = this.imageDataService.getColor(image, x, y)

                if(c.A != 0 && (c.R < 30 && c.G < 30 && c.B < 30) && !entityArray[x][y]) {
                    entityArray[x][y] = 1
                    pointList.push({ x: x-1, y:y })
                    pointList.push({ x: x+1, y:y })
                    pointList.push({ x:x, y: y-1 })
                    pointList.push({ x:x, y: y+1 })
                }
                pointList.splice(i, 1)
                i--
            }
        }

        return entityArray
    }

    getEntireContourUsingContour(entityArray: Array<Uint8Array>, image: GreenImageData, point: Point, contour: Entity) : Array<Uint8Array> {
        let pointList: Point[] = []
        pointList.push(point)

        while(pointList.length > 0) {
            for(let i = 0; i < pointList.length; i++) {
                let {x, y} = pointList[i]
                let c = this.imageDataService.getColor(image, x, y)

                /*
                Changed below condition because in the image at one place the c.R value is 55.
                */
                // if((c.R < 30 && c.G < 30 && c.B < 30) && c.A != 0 && !entityArray[x][y] && !contour.entityArray[x][y]) {
                if(c.A != 0 && ((c.R + c.G + c.B) < 90) && !entityArray[x][y] && !contour.entityArray[x][y]) {
                    entityArray[x][y] = 1
                    pointList.push({ x: x-1, y:y })
                    pointList.push({ x: x+1, y:y })
                    pointList.push({ x:x, y: y-1 })
                    pointList.push({ x:x, y: y+1 })
                }
                pointList.splice(i, 1)
                i--
            }
        }

        return entityArray
    }

    /**
     * Find left/right/top/bottom and width/height of given Entity (contour line or sprinkler)
     * @param entity
     */
    getDimensions(entity: Entity) : Entity {
        let entityArray = entity.entityArray
        let arrWidth = entityArray.length
        let arrHeight = entityArray[0].length

        //Find Bottom
        let botY = 0
        let found = false
        for(let y = arrHeight - 1; y > 0; y--) {
            for(let x = 0; x < arrWidth; x++) {
                if(entityArray[x][y]) {
                    botY = y
                    found = true
                    break;
                }
            }
            if(found) {
                break;
            }
        }
        entity.bottom = botY

        //Find Top
        let topY = 0
        found = false
        for(let y = 0; y < arrHeight; y++) {
            for(let x = 0; x < arrWidth; x++) {
                if(entityArray[x][y]) {
                    topY = y
                    found = true
                    break;
                }
            }
            if(found) {
                break;
            }
        }
        entity.top = topY

        //Find Left
        let leftX = 0
        found = false
        for(let x = 0; x < arrWidth; x++) {
            for(let y = 0; y < arrHeight; y++) {
                if(entityArray[x][y]) {
                    leftX = x
                    found = true
                    break;
                }
            }
            if(found) {
                break;
            }
        }
        entity.left = leftX

        //Find Right
        let rightX = 0
        found = false
        for(let x = arrWidth - 1; x > 0; x--) {
            for(let y = 0; y < arrHeight; y++) {
                if(entityArray[x][y]) {
                    rightX = x
                    found = true
                    break;
                }
            }
            if(found) {
                break;
            }
        }
        entity.right = rightX
        entity.width = entity.right - entity.left
        entity.height = entity.bottom - entity.top

        return entity
    }

    isTransparentPixel(x: number, y: number, image: GreenImageData) {
        let i = (y * (image.width * 4) + x * 4) + 3
        return image.pixels.data[i] == 0
    }

    addPoint(points: Point[], x: number, y: number) : Point[] {
        let p: Point = {
            x: x,
            y: y
        }
        points.push(p)
        return points
    }

    newSprinklerProfile(playground: IgpPlayground) : SprinklerProfile {
        let sprinklerProfile: SprinklerProfile = {
            playground: playground,
            test: {} as ImageData,
            copyrightYear: '',
            contour: {
                initialPoint: {} as Point,
                entityArray: [],
                count: 0,
                width: 0,
                height: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            },
            image: {
                pixels: {} as ImageData,
                width: 0,
                height: 0,
                top: 0,
                bottom: 0,
                right: 0,
                left: 0
            },
            bottomNormal: {} as Point,
            topNormal: {} as Point,
            sprinks: [],
            others: [],
            deadZones: [],
            topSprinklerLoc: {} as Point,
            botSprinklerLoc: {} as Point,
            topSprinkler: 0,
            botSprinkler: 0,
            yardLength: 0,
            greenDepth: 0,
            topCurvePoints: [],
            botCurvePoints: [],
            topCurveLength: 0,
            botCurveLength: 0,
            originalFileName: ""
        }
        return sprinklerProfile
    }

    async getSprinklerProfile(imageData: ImageData, greenDepth: number, copyrightYear: string, playground:IgpPlayground, id:string) : Promise<SprinklerProfile> {
        console.log(`IMG${id}-`+'generated-sprinkler-profile | START')

        let sprinklerProfile: SprinklerProfile = this.newSprinklerProfile(playground)
        sprinklerProfile.greenDepth = greenDepth
        sprinklerProfile.copyrightYear = copyrightYear
        sprinklerProfile.image = this.imageDataService.greenImage(imageData)
        sprinklerProfile.test = imageData
        console.time(`IMG${id}-`+"CONTOUR-TIME")
        // detect contour line
        sprinklerProfile = this.findContour(sprinklerProfile, imageData)
        console.timeEnd(`IMG${id}-`+"CONTOUR-TIME")
        console.time(`IMG${id}-`+"NORMAL-TIME")
        // detect normal points
        sprinklerProfile = this.findNormal(sprinklerProfile)
        // @TODO - Canvas Drawing
        this.drawNormals(sprinklerProfile, playground)
        console.timeEnd(`IMG${id}-`+"NORMAL-TIME")
        console.time(`IMG${id}-`+"SPRINKLER-TIME")
        // detect sprinklers (profile.sprinks, profile.others(text))
        sprinklerProfile = this.findSprinklers(sprinklerProfile)
        console.timeEnd(`IMG${id}-`+"SPRINKLER-TIME")
        console.time(`IMG${id}-`+"CLOSEST-SPRINKLER-TIME")
        // detect sprinklers nearest to top and bottom normal points
        // topSprinkler/botSprinkler/topSprinklerLoc/botSprinklerLoc
        sprinklerProfile = this.findClosestSprinklers(sprinklerProfile)
        console.timeEnd(`IMG${id}-`+"CLOSEST-SPRINKLER-TIME")
        console.time(`IMG${id}-`+"WALK-1-TIME")
        this.isShrinked = false
        // create walk curve to top normal
        sprinklerProfile = await this.createWalk(playground, sprinklerProfile, sprinklerProfile.topNormal, sprinklerProfile.topSprinklerLoc, true)
        console.timeEnd(`IMG${id}-`+"WALK-1-TIME")
        console.time(`IMG${id}-`+"WALK-2-TIME")
        // create walk curve to bottom normal
        sprinklerProfile = await this.createWalk(playground, sprinklerProfile, sprinklerProfile.bottomNormal, sprinklerProfile.botSprinklerLoc, false)
        console.timeEnd(`IMG${id}-`+"WALK-2-TIME")

        console.log(`IMG${id}-`+'generated-sprinkler-profile | END | ', sprinklerProfile)
        return sprinklerProfile
    }

    findContour(sprinklerProfile: SprinklerProfile, imageData: ImageData) : SprinklerProfile {
        let image = sprinklerProfile.image
        let yPos = 0
        let onBlack = false

        // find bottom mid-point on the contour
        // start from center of the image and move down till bottom looking for black pixel
        let x = Math.round(image.width/2)
        for(let y = Math.round(image.height/2); y < image.height; y++) {
            let c = this.imageDataService.getColor(image, x, y)
            if((c.R + c.G + c.B < 90) && c.A != 0 && !onBlack) {
                // first pixel on the contour(inner edge of the line)
                yPos = y
                onBlack = true
            }
            // if(c.A == 0 && onBlack) {
            if((!(c.R + c.G + c.B < 90) || c.A == 0) && onBlack) {
                // previous pixel was the last pixel on the contour(outer edge)
                yPos = y - 1
                break;
            }
        }
        let point: Point = {
            x: x,
            y: yPos
        }
        // detect the entire contour start with bottom mid-point
        sprinklerProfile.contour = this.getNewEntity(point, image, true)
        sprinklerProfile.yardLength = sprinklerProfile.contour.height/sprinklerProfile.greenDepth

        return sprinklerProfile
    }

    findNormal(sprinklerProfile: SprinklerProfile) : SprinklerProfile {
        let test = sprinklerProfile.test
        let contour = sprinklerProfile.contour
        let onContour = false
        let lineFound = false
        let length = 0

        // let p: Point = {
        //     x: 0,
        //     y: 0
        // }
        // sprinklerProfile.bottomNormal = p
        // sprinklerProfile.topNormal = p
        let bottomNormal = sprinklerProfile.bottomNormal
        let topNormal = sprinklerProfile.topNormal

        for(let y = test.height - 1; y >= 0; y--) {
            for(let x = 0; x < test.width; x++) {
                if(contour.entityArray[x][y] && !onContour) {
                    length++
                    onContour = true
                    lineFound = true

                    bottomNormal.x = Math.round(x)
                    bottomNormal.y = Math.round(y)
                }
                else if(contour.entityArray[x][y] && onContour) {
                    length++
                }
                else if(!contour.entityArray[x][y] && onContour) {
                    break;
                }
            }
            if(lineFound)
                break;
        }

        bottomNormal.x += Math.round(length/2)
        // bottomNormal.y = bottomNormal.y

        // find the inner edge of the contour directly above the bottom normal point
        onContour = true
        for(let y = bottomNormal.y; y >= 0; y--) {
            if(!contour.entityArray[Math.round(bottomNormal.x)][y] && onContour) {
                onContour = false
            }
            else if(contour.entityArray[Math.round(bottomNormal.x)][y] && !onContour) {
                topNormal.x = Math.round(bottomNormal.x)
                topNormal.y = Math.round(y)
            }
        }

        // adjust topNormal to the outer edge of the contour
        onContour = true
        for(let y = topNormal.y; y >= 0; y--) {
            if(!contour.entityArray[topNormal.x][y] && onContour) {
                onContour = false
                topNormal.y = Math.round(y - 1)
            }
        }

        return sprinklerProfile
    }

    drawNormals(sprinklerProfile: SprinklerProfile, playground:IgpPlayground) {
        let test = sprinklerProfile.test
        let canvas = playground.canvas
        canvas.width = test.width
        canvas.height = test.height
        let ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
        ctx.putImageData(test, 0, 0)

        this.drawNormal(ctx, sprinklerProfile.topNormal, sprinklerProfile.bottomNormal)
        sprinklerProfile.test = ctx.getImageData(0, 0, canvas.width, canvas.height)
    }

    drawNormal(ctx: CanvasRenderingContext2D, topNormal: Point, bottomNormal: Point, widthRatio: number = 1, heightRatio: number = 1, isShrinked: boolean = false) {
        ctx.lineWidth = 3
        if(isShrinked)
            ctx.lineWidth = 1
        ctx.moveTo(bottomNormal.x * widthRatio, bottomNormal.y * heightRatio)
        ctx.lineTo(topNormal.x * widthRatio, topNormal.y * heightRatio)
        ctx.stroke()
    }

    findSprinklers(sprinklerProfile: SprinklerProfile) : SprinklerProfile {
        let ts:TimerService = new TimerService()
        let sprinks: Rectangle[] = []  // sprinkler cross bounds
        let others: Rectangle[] = []  // other things like text next to sprinkler cross
        let deadZones: Array<Point[]> = []
        
        let image = sprinklerProfile.image
        let contour = sprinklerProfile.contour

        console.log(`findSprinklers: size: ${image.width} x ${image.height}`)
        let entity: Entity = {
            initialPoint: {x:0, y:0},
            entityArray: this.getEntityArray(image),
            count: 0,
            width: 0,
            height: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }

        for(let y = 0; y < image.height; y++) {
            for(let x = 0; x < image.width; x++) {
                let contains = false
                let newRight = 0

                for(let i = 0; i < sprinks.length; i++) {
                    let isInside = this.isPointInRect(sprinks[i], x, y);
                    if(isInside) {
                        contains = true
                        newRight = sprinks[i].x + sprinks[i].width
                        break;
                    }
                }
                for(let i = 0; i < others.length; i++) {
                    let isInside = this.isPointInRect(others[i], x, y)
                    if(isInside) {
                        contains = true
                        newRight = others[i].x + others[i].width
                        break;
                    }
                }

                if(contour.entityArray[x][y]) {
                    contains = true
                    newRight = x
                }

                let c = this.imageDataService.getColor(image, x, y)

                // black pixel and not in contour line => could be sprinkler or text
                if(!contains && (c.R + c.G + c.B < 90) && c.A != 0 && !contour.entityArray[x][y]) {
                    let point: Point = {
                        x: Math.round(x),
                        y: Math.round(y)
                    }

                    ts.start('newContour');
                    ts.start('reset-entity-array')
                    entity.entityArray.map(a => a.fill(0))
                    ts.end('reset-entity-array')
                    this.getNewEntityUsingContour(point, image, contour,entity, ts)
                    ts.end('newContour');

                    let ratio = entity.width/entity.height

                    let rect: Rectangle = {
                        x: Math.round(entity.left - 1),
                        y: Math.round(entity.top - 1),
                        width: Math.round(entity.width + 2),
                        height: Math.round(entity.height + 2),
                        x2: Math.round(entity.left + entity.width + 1),
                        y2: Math.round(entity.top + entity.height + 1)
                    }
                    if(ratio > .90 && ratio < 1.1 && entity.width < 300 && entity.height < 300) {
                        sprinks.push(rect)
                    }
                    else {
                        others.push(rect)
                    }

                    x = entity.right
                }

                if(contains) {
                    x = newRight
                }
            }
        }

        console.log(`findSprinklers: ${ts}`)

        sprinklerProfile.sprinks = sprinks
        sprinklerProfile.others = others
        sprinklerProfile.deadZones = deadZones

        console.log('Sprinklers', sprinks)

        return sprinklerProfile
    }

    isPointInRect(rectangle: Rectangle, x: number, y: number) : boolean {
        return !(x < rectangle.x || x > rectangle.x2 ||
          y < rectangle.y || y > rectangle.y2);

        // ctx.beginPath()
        // ctx.rect(rectangle.x, rectangle.y, rectangle.width, rectangle.height)
        // return ctx.isPointInPath(x, y)
    }

    /**
     * Detect sprinklers closest to the normal points
     * @param sprinklerProfile
     */
    findClosestSprinklers(sprinklerProfile: SprinklerProfile) : SprinklerProfile {
        let closestToTopSprinkler: number = 0
        let secondClosestToTopSprinkler: number = 0
        let closestToBotSprinkler: number = 0
        let secondClosestToBotSprinkler: number = 0

        let topSprinklerLoc = sprinklerProfile.topSprinklerLoc
        let botSprinklerLoc = sprinklerProfile.botSprinklerLoc
        let topSprinkler = sprinklerProfile.topSprinkler
        let botSprinkler = sprinklerProfile.botSprinkler

        let closestToTopDistance = 10000
        let secondClosestToTopDistance = 10000
        let closestToBotDistance = 10000
        let secondClosestToBotDistance = 10000

        let sprinks = sprinklerProfile.sprinks
        let topNormal = sprinklerProfile.topNormal
        let bottomNormal = sprinklerProfile.bottomNormal
        for(let i = 0; i < sprinks.length; i++) {
            let topDistance = Math.sqrt(Math.pow(Math.abs(sprinks[i].x + (sprinks[i].width/2) - topNormal.x), 2) + 
                Math.pow(Math.abs(sprinks[i].y + (sprinks[i].height/2) - topNormal.y), 2))
            let botDistance = Math.sqrt(Math.pow(Math.abs(sprinks[i].x + (sprinks[i].width/2) - bottomNormal.x), 2) + 
                Math.pow(Math.abs(sprinks[i].y + (sprinks[i].height/2) - bottomNormal.y), 2))

            if(topDistance < closestToTopDistance) {
                closestToTopDistance = topDistance
                closestToTopSprinkler = i
            }
            else if(topDistance < secondClosestToTopDistance) {
                secondClosestToTopDistance = topDistance
                secondClosestToTopSprinkler = i
            }

            if(botDistance < closestToBotDistance) {
                closestToBotDistance = botDistance
                closestToBotSprinkler = i
            }
            else if(botDistance < secondClosestToBotDistance) {
                secondClosestToBotDistance = botDistance
                secondClosestToBotSprinkler = i
            }
        }

        if(closestToTopSprinkler == closestToBotSprinkler) {
            if(closestToTopDistance < closestToBotDistance) {
                closestToBotSprinkler = secondClosestToBotSprinkler
            }
            else {
                closestToTopSprinkler = secondClosestToTopSprinkler
            }
        }

        topSprinkler = closestToTopSprinkler
        botSprinkler = closestToBotSprinkler

        topSprinklerLoc.x = Math.round(sprinks[topSprinkler].x) + Math.round(sprinks[topSprinkler].width/2)
        topSprinklerLoc.y = Math.round(sprinks[topSprinkler].y) + Math.round(sprinks[topSprinkler].height/2)
        botSprinklerLoc.x = Math.round(sprinks[botSprinkler].x) + Math.round(sprinks[botSprinkler].width/2)
        botSprinklerLoc.y = Math.round(sprinks[botSprinkler].y) + Math.round(sprinks[botSprinkler].height/2)

        sprinklerProfile.topSprinkler = topSprinkler
        sprinklerProfile.botSprinkler = botSprinkler
        sprinklerProfile.topSprinklerLoc = topSprinklerLoc
        sprinklerProfile.botSprinklerLoc = botSprinklerLoc

        return sprinklerProfile
    }

    async createWalk(playground:IgpPlayground, sprinklerProfile:SprinklerProfile, normal: Point, sprinkler: Point, isTop: boolean) : Promise<SprinklerProfile> {
        let yardLength = sprinklerProfile.yardLength

        if(isTop)
            sprinkler.y = sprinkler.y - 5
        else
            sprinkler.y = sprinkler.y + 5
            
        let curvePoints: Point[] = []
        curvePoints.push(sprinkler)

        let collisionNormal = {...normal}
        let curvePoint = {} as Point

        let currentIndex = 0
        let collisions = false
        let noError = true

        while(curvePoints[currentIndex] != normal && noError) {
            collisions = true
            collisionNormal = {...normal}

            if(isTop)
                collisionNormal.y = collisionNormal.y - 5
            else
                collisionNormal.y = collisionNormal.y + 5

            while(collisions) {
                let collisionResult = this.collision(curvePoints[currentIndex], collisionNormal, currentIndex==0, normal, sprinklerProfile)
                if(collisionResult == 1) {
                    // collision with contour. Move up or down
                    if(isTop) {
                        // move up
                        collisionNormal.y = collisionNormal.y - 1
                    } else {
                        // move down
                        collisionNormal.y = collisionNormal.y + 1
                    }
                }
                else if(collisionResult == 0) {
                    // no collision. draw the curve from sprinkler to the normal point
                    curvePoint = this.calculateCurvePoint(curvePoints[currentIndex], collisionNormal, currentIndex == 0, isTop, sprinklerProfile)
                    curvePoints.push(curvePoint)
                    currentIndex++
                    collisions = false
                }
                else if(collisionResult == -1) {
                    // error in collision detection
                    collisions = false
                    noError = false
                }
                else {
                    // could be collision with normal(2)
                    curvePoints.push(normal)
                    currentIndex++
                    collisions = false
                }
            }
        }
        if (noError) {
            isTop ? sprinklerProfile.topCurvePoints = curvePoints : sprinklerProfile.botCurvePoints = curvePoints

            let length = 0
            for(let i = 0; i < curvePoints.length - 1; i++) {
                length += Math.sqrt(Math.pow((curvePoints[i].y - curvePoints[i+1].y), 2) +
                  Math.pow((curvePoints[i].x - curvePoints[i + 1].x), 2))
            }

            length /= yardLength
            let intLength = Math.round(length)
            isTop ? sprinklerProfile.topCurveLength = intLength : sprinklerProfile.botCurveLength = intLength
        }

        return sprinklerProfile
    }

    collision(A: Point, B: Point, useDelay: boolean, normal: Point, sprinklerProfile: SprinklerProfile) : number {
        let image = sprinklerProfile.image
        let sprinks = sprinklerProfile.sprinks
        let others = sprinklerProfile.others

        if(B.y > image.height * 2 || B.y < (image.height * -2)) {
            console.error("Returning -1")
            return -1
        }

        let x0 = A.x
        let y0 = A.y

        let x1 = B.x
        let y1 = B.y

        let dx = Math.abs(x1 - x0)
        let dy = Math.abs(y1 - y0)

        let sx = 0
        let sy = 0

        if(x0 < x1)
            sx = 1
        else
            sx = -1

        // sx = (x0 < x1) ? 1 : -1
        
        if(y0 < y1)
            sy = 1
        else
            sy = -1

        // sy = (y0 < y1) ? 1 : -1

        let err = dx - dy

        let done = false

        let delay: number

        if(useDelay) {
            if(sprinks.length > 0)
                delay = sprinks[0].width/2 + 1
            else
                delay = 50
        }  
        else {
            delay = 0
        }

        // delay = useDelay ? (sprinks.length > 0 ? sprinks[0].width/2 + 1 : 50) : 0

        while(!done) {
            let e2 = 2 * err

            if(e2 > (dy * -1)) {
                err = err - dy
                x0 = x0 + sx
            }

            if(e2 < dx) {
                err = err + dx
                y0 = y0 + sy
            }

            if(x0 == normal.x && (y0 == normal.y || y0 == normal.y - 5 || y0 == normal.y + 5)) {
                return 2
            }

            if(x0 > 0 && x0 < image.width && y0 > 0 && y0 < image.height && !(x0 == x1 && y0 == y1)) {
                let c = this.imageDataService.getColor(image, Math.round(x0), Math.round(y0))

                if(delay < 1) {
                    // if((c.R + c.G + c.B < 90) && c.A != 0 && done != true) {
                    if((c.R + c.G + c.B < 90) && c.A != 0) {
                        let othersContains = false

                        for(let i = 0; i < others.length; i++) {
                            if(this.isPointInRect(others[i], x0, y0)) {
                                othersContains = true
                                break;
                            }
                        }

                        if(!othersContains) {
                            return 1
                        }
                    }
                }
                else {
                    delay--
                }
            }
            else {
                done = true
            }
        }
        
        return 0
    }

    calculateCurvePoint(start: Point, normalTarget: Point, useDelay: boolean, isTop: boolean, sprinklerProfile: SprinklerProfile) : Point {
        if(isTop) {
            normalTarget.y = normalTarget.y + 1
        }
        else {
            normalTarget.y = normalTarget.y - 1
        }

        let sprinks = sprinklerProfile.sprinks
        let others = sprinklerProfile.others
        let image = sprinklerProfile.image
        let yardLength = sprinklerProfile.yardLength

        let goingRight = start.x <= normalTarget.x
        let goingUp = start.y >= normalTarget.y

        let x0 = start.x
        let y0 = start.y

        let x1 = normalTarget.x
        let y1 = normalTarget.y

        let dx = Math.abs(x1 - x0)
        let dy = Math.abs(y1 - y0)

        let sx = 0
        let sy = 0

        if(x0 < x1)
            sx = 1
        else
            sx = -1

        // sx = (x0 < x1) ? 1 : -1
        
        if(y0 < y1)
            sy = 1
        else
            sy = -1

        // sy = (y0 < y1) ? 1 : -1

        let err = dx - dy

        let done = false

        let delay: number

        if(useDelay) {
            if(sprinks.length > 0)
                delay = sprinks[0].width/2 + 1
            else
                delay = 50
        }  
        else {
            delay = 0
        }

        // delay = useDelay ? (sprinks.length > 0 ? sprinks[0].width/2 + 1 : 50) : 0

        while(!done) {
            let e2 = 2 * err

            if(e2 > (dy * -1)) {
                err = err - dy
                x0 = x0 + sx
            }

            if(e2 < dx) {
                err = err + dx
                y0 = y0 + sy
            }

            if(x0 >= 0 && x0 < image.width && y0 >= 0 && y0 < image.height) {
                let c = this.imageDataService.getColor(image, Math.round(x0), Math.round(y0))

                if(delay < 1) {
                    // if((c.R + c.G + c.B < 90) && c.A != 0 && done != true) {
                    if((c.R + c.G + c.B < 90) && c.A != 0) {
                        let othersContains = false

                        for(let i = 0; i < others.length; i++) {
                            if(this.isPointInRect(others[i], x0, y0)) {
                                othersContains = true
                            }
                        }

                        if(!othersContains) {
                            done = true

                            let slope: number

                            slope = Math.atan((start.y - y0)/(x0 - start.x))
                            if(isTop) {
                                slope += Math.PI/2.0
                            }
                            else {
                                slope -= Math.PI/2.0
                            }

                            // slope = isTop ? slope += Math.PI/2.0 : slope -= Math.PI/2.0

                            let tempX = x0 + yardLength/2.0 * Math.cos(slope)
                            let tempY = y0 - yardLength/2.0 * Math.sin(slope)

                            if(Math.round(tempX) >= image.width) {
                                tempX = image.width - 1
                            }
                            if(Math.round(tempX) <= 1) {
                                tempX = 1
                            }

                            if(Math.round(tempY) >= image.height) {
                                tempY = image.height - 1
                            }
                            if(Math.round(tempY) <= 1) {
                                tempY = 1
                            }

                            //Adding Math.round to avoid float types
                            let curvePoint: Point = {
                                x: Math.round(tempX),
                                y: Math.round(tempY)
                            }
                            return curvePoint
                        }
                    }
                }
                else {
                    delay--
                }
            }
            else {
                let curvePoint: Point = {
                    x: -1,
                    y: -1
                }
                return curvePoint
            }
        }
        let curvePoint: Point = {
            x: -1,
            y: -1
        }
        return curvePoint
    }

    drawCurve(ctx: CanvasRenderingContext2D, curvePoints: Point[], isShrinked: boolean = false, widthRatio: number = 1, heightRatio: number = 1) {
        if (!curvePoints.length) {
            console.warn("Skipping drawCurve for empty curvePoints")
            return;
        }
        if(isShrinked) {
            ctx.lineWidth = 1
        }
        ctx.beginPath()
        ctx.moveTo(curvePoints[0].x * widthRatio, curvePoints[0].y * heightRatio);

        let i = 0
        if(curvePoints.length >= 3) {
            for (i = 1; i < curvePoints.length - 2; i ++)
            {
                var xc = ((curvePoints[i].x + curvePoints[i + 1].x) * widthRatio) / 2;
                var yc = ((curvePoints[i].y + curvePoints[i + 1].y) * heightRatio) / 2;
                ctx.quadraticCurveTo(curvePoints[i].x * widthRatio, curvePoints[i].y * heightRatio, xc, yc);
                ctx.stroke()
            }
        }
        // curve through the last two points
        ctx.quadraticCurveTo(curvePoints[i].x * widthRatio, curvePoints[i].y * heightRatio, curvePoints[i+1].x * widthRatio,curvePoints[i+1].y * heightRatio);

        ctx.stroke()
        ctx.closePath()
    }

    drawDistance(ctx: CanvasRenderingContext2D, rectangleTopLeft: Point, yardLength: number, image: GreenImageData, intLength: number, isShrinked: boolean = false,  widthRatio: number = 1, heightRatio: number = 1, increaseFont: boolean = false) {
        let boxTopLeft = {...rectangleTopLeft}
        if(rectangleTopLeft.x + 50 + yardLength < image.width) {
            if(rectangleTopLeft.y + 50 + yardLength < image.height) {
                boxTopLeft.x = Math.round(boxTopLeft.x + yardLength)
                boxTopLeft.y = boxTopLeft.y

                this.drawRectAndText(ctx, boxTopLeft.x, boxTopLeft.y, intLength, isShrinked, widthRatio, heightRatio, increaseFont)
            }
            else {
                boxTopLeft.x = Math.round(boxTopLeft.x + yardLength)
                boxTopLeft.y = boxTopLeft.y - 50

                this.drawRectAndText(ctx, boxTopLeft.x, boxTopLeft.y, intLength, isShrinked, widthRatio, heightRatio, increaseFont)
            }
        }
        else if(rectangleTopLeft.x - 50 - yardLength > 0) {
            if(rectangleTopLeft.y + 50 + yardLength < image.height) {
                boxTopLeft.x = Math.round(boxTopLeft.x - yardLength - 50)
                boxTopLeft.y = boxTopLeft.y

                this.drawRectAndText(ctx, boxTopLeft.x, boxTopLeft.y, intLength, isShrinked, widthRatio, heightRatio, increaseFont)
            }
            else {
                boxTopLeft.x = Math.round(boxTopLeft.x - yardLength - 50)
                boxTopLeft.y = boxTopLeft.y - 50

                this.drawRectAndText(ctx, boxTopLeft.x, boxTopLeft.y, intLength, isShrinked, widthRatio, heightRatio, increaseFont)
            }
        }
    }

    drawRectAndText(ctx: CanvasRenderingContext2D, x: number, y: number, length: number, isShrinked: boolean = false,  widthRatio: number = 1, heightRatio: number = 1, increaseFont: boolean = false) {
        console.log('Increase font:', increaseFont)
        ctx.font = `${increaseFont ? 45 : 39}px Arial`
        if(isShrinked) {
            ctx.lineWidth = 1
            ctx.font = `${(increaseFont ? 45 : 23)*(Math.max(heightRatio, widthRatio)*2)}px Arial`
        }

        ctx.beginPath()
        ctx.rect(x * widthRatio, y * heightRatio, (increaseFont ? (isShrinked ? 110 : 70) : 65) * widthRatio, (increaseFont ?  (isShrinked ? 110 : 70) : 65) * heightRatio)
        ctx.fillStyle = "white"
        ctx.fill()
        // ctx.lineWidth = 1
        ctx.strokeStyle = "black"
        ctx.stroke()

        ctx.beginPath()
        ctx.fillStyle = "black"
        if(length < 10) {
            ctx.fillText(length.toString(), (x + 15) * widthRatio, (y + (increaseFont ? (isShrinked ? 85 : 55) : 45)) * heightRatio)
        }
        else {
            ctx.fillText(length.toString(), (x + 5) * widthRatio, (y + (increaseFont ? (isShrinked ? 85 : 55) : 45)) * heightRatio)
        }
    }

    setCurrentSprinklerToCanvas(sprinklerProfile: SprinklerProfile, canvasContainer: HTMLElement) {
        let canvas = <HTMLCanvasElement>canvasContainer.querySelector(".image-canvas")
        canvas.width = sprinklerProfile.playground.canvas.width
        canvas.height = sprinklerProfile.playground.canvas.height
        let ctx = <CanvasRenderingContext2D>canvas.getContext('2d')

        ctx.drawImage(sprinklerProfile.playground.canvas, 0, 0)

        let widthRatio = this.widthRatio;
        let heightRatio = this.heightRatio;
        let isShrunk = (widthRatio != 1 || heightRatio != 1)

        this.drawNormal(ctx, sprinklerProfile.topNormal, sprinklerProfile.bottomNormal, widthRatio, heightRatio, isShrunk)
        this.drawCurve(ctx, sprinklerProfile.topCurvePoints, isShrunk, widthRatio, heightRatio)
        this.drawCurve(ctx, sprinklerProfile.botCurvePoints, isShrunk, widthRatio, heightRatio)
        this.drawDistance(ctx, sprinklerProfile.topSprinklerLoc, sprinklerProfile.yardLength, sprinklerProfile.image, sprinklerProfile.topCurveLength, isShrunk, widthRatio, heightRatio, (sprinklerProfile.test.width > 3000  || sprinklerProfile.test.height > 3000))
        this.drawDistance(ctx, sprinklerProfile.botSprinklerLoc, sprinklerProfile.yardLength, sprinklerProfile.image, sprinklerProfile.botCurveLength, isShrunk, widthRatio, heightRatio, (sprinklerProfile.test.width > 3000  || sprinklerProfile.test.height > 3000))

        this.resizeCanvas(canvas, sprinklerProfile)
    }



    resizeCanvas(canvas:HTMLCanvasElement, sprinklerProfile:SprinklerProfile) {
        // canvas.width = sprinklerProfile.test.width
        // canvas.height = sprinklerProfile.test.height
        // ctx.clearRect(0, 0, canvas.width, canvas.height)
        // ctx.putImageData(sprinklerProfile.test, 0, 0)
        //
        // if(!this.isShrinked) {
        //     let [widthRatio, heightRatio] = await this.resizeSprinkler(canvas, playground.image)
        //     this.widthRatio = widthRatio
        //     this.heightRatio = heightRatio
        //     this.isShrinked = true
        // }

        canvas.style.maxWidth = `100%`
        canvas.style.maxHeight = `100%`
        return

        let imageWidth = sprinklerProfile.playground.image.width;
        let imageHeight = sprinklerProfile.playground.image.height;

        let widthRatio = imageWidth / this.resizeWidth;
        let heightRatio = imageHeight / this.resizeHeight;

        let newWidth = imageWidth;
        let newHeight = imageHeight;
        if(heightRatio > widthRatio) {
            // scale image to match the height, and let the width have the gaps
            newHeight = this.resizeHeight;
            newWidth = (imageWidth/imageHeight)*newHeight;
        } else {
            // scale image to match the width, and let the height have the gaps
            newWidth = this.resizeWidth;
            newHeight = (imageHeight/imageWidth)*newWidth;
        }

        canvas.style.width = `${newWidth}px`
        canvas.style.height = `${newHeight}px`
    }

    resizeSprinkler(canvas: HTMLCanvasElement, image: HTMLImageElement) : Promise<number[]> {
        return new Promise((resolve, reject) => {
            image.src = canvas.toDataURL();

            let imageWidth = canvas.width
            let imageHeight = canvas.height
    
            let finalWidth = imageWidth
            let finalHeight = imageHeight

            if(imageHeight > this.resizeHeight) {
                let ratio = this.resizeHeight/imageHeight
                let tempWidth = ratio * imageWidth
                let tempHeight = this.resizeHeight
                let ratio2 =1
                if(tempWidth > this.resizeWidth) {
                    ratio2 = this.resizeWidth/tempWidth
                    tempWidth = this.resizeWidth
                }
                finalHeight = ratio2 * tempHeight
                finalWidth = tempWidth
            } else if(imageWidth > this.resizeWidth) {
                let ratio = this.resizeWidth/imageWidth
                finalHeight = ratio * imageHeight
                finalWidth = this.resizeWidth
            }
            let ctx = <CanvasRenderingContext2D>canvas.getContext('2d')
    
            canvas.width = finalWidth
            canvas.height = finalHeight
    
            image.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(image, 0, 0, finalWidth, finalHeight)
    
                let widthRatio = finalWidth/imageWidth
                let heightRatio = finalHeight/imageHeight
    
                resolve([widthRatio, heightRatio])
            }
        })
    }

    getSprinklerImages(sprinklerProfiles: SprinklerProfile[], canvasContainer: HTMLElement) : any[] {
        let sprinklerImages: any[] = []
        let canvas = <HTMLCanvasElement>canvasContainer.querySelector(".hidden-canvas")
        let ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
        for(let i = 0; i < sprinklerProfiles.length; i++) {
            let sprinklerProfile = sprinklerProfiles[i]
            canvas.width = sprinklerProfile.image.width
            canvas.height = sprinklerProfile.image.height
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.putImageData(sprinklerProfile.image.pixels, 0, 0)
            this.drawNormal(ctx, sprinklerProfile.topNormal, sprinklerProfile.bottomNormal)
            this.drawCurve(ctx, sprinklerProfile.topCurvePoints)
            this.drawCurve(ctx, sprinklerProfile.botCurvePoints)
            this.drawDistance(ctx, sprinklerProfile.topSprinklerLoc, sprinklerProfile.yardLength, sprinklerProfile.image, sprinklerProfile.topCurveLength, (sprinklerProfile.test.width > 3000  || sprinklerProfile.test.height > 3000))
            this.drawDistance(ctx, sprinklerProfile.botSprinklerLoc, sprinklerProfile.yardLength, sprinklerProfile.image, sprinklerProfile.botCurveLength, (sprinklerProfile.test.width > 3000  || sprinklerProfile.test.height > 3000))
            let sprinklerImage: any = {
                name: sprinklerProfile.originalFileName,
                image: canvas.toDataURL()
            }
            sprinklerImages.push(sprinklerImage)
        }
        return sprinklerImages
    }

    async loadSprinklerBook(jsonFile: File, canvasContainer: HTMLElement, ngxLoader: NgxUiLoaderService, data: any, loadFromFile: boolean = false) : Promise<any[]> {
        this.canvasContainer = canvasContainer
        let currentGreen = 0
        let savedData : SaveSprinklerModel = {
            sprinklerProfiles: [],
            sprinklerConfig: undefined,
            clubName: "",
            courseName: "",
            copyrightYear: "",
            type: "",
            versionNumber: "",
            source: "",
            lastModifiedDate: "",
            clientID: "",
            courseID: ""
        }
        let linkClientCourse = false
        if(loadFromFile) {
            [savedData, linkClientCourse] = await this.readJsonFile(jsonFile, savedData, ngxLoader)
        }
        else {
            savedData = this.readDataFromSprinklerData(savedData, data)
        }
        this.sprinklerProfiles = await this.getSprinklerProfilesFromSaveSprinklerProfiles(savedData.sprinklerProfiles, canvasContainer)
        this.setCurrentSprinklerToCanvas(this.sprinklerProfiles[currentGreen], canvasContainer)
        return [this.sprinklerProfiles, savedData, linkClientCourse]
    }

    readDataFromSprinklerData(savedData: SaveSprinklerModel, sprinklerData: any) {
        savedData.sprinklerProfiles = JSON.parse(sprinklerData.SprinklerProfiles)
        savedData.sprinklerConfig = JSON.parse(sprinklerData.Config)
        savedData.copyrightYear = sprinklerData.CopyrightYear
        savedData.clubName = sprinklerData.ClientName
        savedData.courseName = sprinklerData.CourseName
        savedData.clientID = sprinklerData.ClientID
        savedData.courseID = sprinklerData.CourseID

        return savedData
    }

    readJsonFile(jsonFile: File, savedData: SaveSprinklerModel, ngxLoader: NgxUiLoaderService): Promise<[SaveSprinklerModel, boolean]> {
        return new Promise((resolve, reject) => {
            let newKeys = ['clientID', 'courseID']
            let jsonReader = new FileReader()
            let linkClientCourse = false
            jsonReader.readAsText(jsonFile)
            jsonReader.onload = () => {
                let jsonData = JSON.parse(jsonReader.result as string)
                let jsonKeys = Object.keys(jsonData)
                let savedDataKeys = Object.keys(savedData)
                if(savedDataKeys.length != jsonKeys.length && !(jsonKeys.includes(newKeys[0]) && jsonKeys.includes(newKeys[1]))) {
                    linkClientCourse = true
                    savedDataKeys = savedDataKeys.filter((v: any) => {
                        if(!newKeys.includes(v)) {
                            return v
                        }
                    })
                }
                if(jsonKeys.includes('type') && jsonData.type == 'SprinklerBook') {
                    if(JSON.stringify(jsonKeys.sort()) != JSON.stringify(savedDataKeys.sort())) {
                        alert("Invalid json file selected.")
                        ngxLoader.stop()
                    }
                    else {
                        savedData = jsonData
                        console.log(savedData, '***jsoncontent')
                        resolve([savedData, linkClientCourse])
                    }
                }
                else {
                    alert("Invalid json file selected.")
                    ngxLoader.stop()
                }
            }
        })
    }

    async getSprinklerProfilesFromSaveSprinklerProfiles(savedSprinklerProfiles: SaveSprinklerProfile[], canvasContainer: HTMLElement) : Promise<SprinklerProfile[]> {
        let segmentProfiles: SprinklerProfile[] = []
        
        for(let i=0; i<savedSprinklerProfiles.length; i++) {
            let savedSprinklerProfile = savedSprinklerProfiles[i]
            let playground = this.newPlayground()
            let segmentProfile: SprinklerProfile = {
                originalFileName: savedSprinklerProfile.originalFileName,
                image: this.imageDataService.greenImage(await this.convertStringToImageData(savedSprinklerProfile.image, playground)),
                playground: playground,
                test: {} as ImageData,
                copyrightYear: '',
                contour: {
                    initialPoint: {} as Point,
                    entityArray: [],
                    count: 0,
                    width: 0,
                    height: 0,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0
                },
                bottomNormal: savedSprinklerProfile.bottomNormal,
                topNormal: savedSprinklerProfile.topNormal,
                botCurvePoints: savedSprinklerProfile.botCurvePoints,
                topCurvePoints: savedSprinklerProfile.topCurvePoints,
                botCurveLength: savedSprinklerProfile.botCurveLength,
                topCurveLength: savedSprinklerProfile.topCurveLength,
                botSprinklerLoc: savedSprinklerProfile.botSprinklerLoc,
                topSprinklerLoc: savedSprinklerProfile.topSprinklerLoc,
                sprinks: [],
                others: [],
                deadZones: [],
                topSprinkler: 0,
                botSprinkler: 0,
                yardLength: savedSprinklerProfile.yardLength,
                greenDepth: savedSprinklerProfile.greenDepth
            }
            segmentProfiles.push(segmentProfile)
            // let segmentProfile : SegmentProfile = savedSprinklerProfiles[i]
        }

        return segmentProfiles
    }

    convertStringToImageData(imgString: String, playground: IgpPlayground) : Promise<ImageData> {
        return new Promise((resolve, reject) => {
            let canvas = playground.canvas
            let ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
            let image = new Image()
            image.src = "data:image/png;base64," + imgString
            let greenImg = playground.image
            greenImg.src = image.src
            image.onload = () => {
                canvas.width = image.width
                canvas.height = image.height
                ctx = <CanvasRenderingContext2D>canvas.getContext("2d")
                ctx.drawImage(image, 0, 0)
                resolve(ctx.getImageData(0, 0, canvas.width, canvas.height))
            }
        })
    }
}