import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginLayoutComponent } from "./login-layout/login-layout.component";
import { AuthGuard } from "src/app/core/services/auth/auth.guard";
import { LayoutComponent } from "./layout.component";
import { SecureInnerPagesGuard } from "src/app/core/services/auth/secure-inner-pages.guard";
const routes: Routes = [
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                canActivate: [SecureInnerPagesGuard],
                loadChildren: () => import('../login/login.module').then(m => m.LoginModule)
            },
            {
                path: 'login',
                redirectTo: ''
            },
            {
                path: 'authorize',
                // canActivate: [SecureInnerPagesGuard],
                loadChildren: () => import('../authorize/authorize.module').then(m => m.AuthorizeModule)
            },
            {
                path: 'logout',
                // canActivate: [AuthGuard],
                loadChildren: () => import('../logout/logout.module').then(m => m.LogoutModule)
            },
            
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'client',
                canActivate: [AuthGuard],
                loadChildren: () => import('../client-info/client-info.module').then(m => m.ClientInfoModule)
            },
            {
                path: 'igp',
                canActivate: [AuthGuard],
                loadChildren: () => import('../igp/igp.module').then(m => m.IGPModule)
            },
            {
                path: 'super-admin',
                canActivate: [AuthGuard],
                loadChildren: () => import('../super-admin/super-admin.module').then(m => m.SuperAdminModule)
            },
            {
                path: 'external-access',
                canActivate: [AuthGuard],
                loadChildren: () => import('../external-access/external-access.module').then(m => m.ExternalAccessModule)
            },
            {
                path: 'training-library',
                canActivate: [AuthGuard],
                loadChildren: () => import('../training-library/training-library.module').then(m => m.TrainingLibraryModule)
            }
        ]
    },
]
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class LayoutRoutingModule { }