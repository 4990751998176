import { createAction, props } from "@ngrx/store";
export const clientsDetails = createAction(
    '[Clients data] Retrieve cllients data', props<{ clientsData: Record<string, string>[] }>()
)
export const showInactiveAction = createAction(
    '[Show Inactive client]', props<{ showInactive: boolean }>()
)
export const userProfileAction = createAction(
    '[Get user profile data]', props<{ userProfile: [] }>()
)
export const addUpdateClientAction = createAction(
    '[User Add or Update]',props<{addUpdateClient:boolean}>()
)
export const newUserAction = createAction(
    '[User Add or Update]',props<{newUser:boolean}>()
)
export const courseList = createAction(
    '[Change in Course List]', props<{ courseListData: any }>()
)