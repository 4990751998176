import { NgModule } from '@angular/core';
import { BrowserModule, Title} from '@angular/platform-browser';

// Service
import { HttpService } from './core/services/http/http.service';
import { CookieService } from 'ngx-cookie-service';

// Module
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from './features/layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { clearState } from './store/reducers/logout.reducer';
import { addUpdateClientReducer, clientsReducer, showInactiveReducer,userProfileReducer,newUserReducer, courseListReducer } from './store/reducers/clients.reducer';
import { addUpdateReducer } from './store/reducers/user.reducer';
import { userInfoReducer } from './store/reducers/user.reducer';
// Components

import { LayoutComponent } from './features/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { ClientInfoComponent } from './features/client-info/client-info.component';
import { CourseComponent } from './features/client-info/course/course.component';
import { UserComponent } from './features/client-info/user/user.component';
import { ProfileComponent } from './features/client-info/profile/profile.component';
import { UserFormComponent } from './features/client-info/user/user-form/user-form.component';
import { ClientdropdownComponent } from './components/clientdropdown/clientdropdown.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LoginComponent } from './features/login/login.component';
import { MultipleSelectionComponent } from './components/multiple-selection/multiple-selection.component';
import { IGPHeaderComponent } from './features/igp/header/header.component';
import { SideNavComponent } from './features/igp/side-nav/side-nav.component';
import { CanvasComponent } from './features/igp/canvas/canvas.component';
import { PdfComponent } from './features/igp/pdf/pdf.component';
import { IGPComponent } from './features/igp/igp.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SegmentBookService } from './IGP-functions/segment-book-service';
import { SprinklerBookService } from './IGP-functions/sprinkler-book-service';
import { ImageDataService } from './IGP-functions/image-data-service';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MultidropdownComponent } from './features/igp/multidropdown/multidropdown.component';
import { SegmentComponent } from './features/igp/segment/segment.component';
import { SprinklerComponent } from './features/igp/sprinkler/sprinkler.component';
import { FontDropdownComponent } from './features/igp/font-dropdown/font-dropdown.component';
import { RangeComponent } from './features/igp/range/range.component';
import { SuperAdminComponent } from './features/super-admin/super-admin.component';
import { AuthorizeComponent } from './features/authorize/authorize.component';
import { LogoutComponent } from './features/logout/logout.component';
import { ExternalAccessComponent } from './features/external-access/external-access.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { TrainingLibraryComponent } from './features/training-library/training-library.component';
import { ThreeDComponent } from './features/igp/three-d/three-d.component';
import { ThreeDService } from './features/igp/three-d-service';
import { ThreeDViewerComponent } from './features/igp/three-d-viewer/three-d-viewer.component';
import { TopoPdfComponent } from './features/igp/topo-pdf/topo-pdf.component';
import { SegmentBookComponent } from './features/client-info/segment-book/segment-book.component';
import { SprinklerBookComponent } from './features/client-info/sprinkler-book/sprinkler-book.component';
import { LidarProcessComponent } from './features/client-info/lidar-process/lidar-process.component';

export const metaReducers: MetaReducer<any>[] = [clearState]
// 
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    ClientInfoComponent,
    CourseComponent,
    UserComponent,
    ProfileComponent,
    UserFormComponent,
    ClientdropdownComponent,
    DropdownComponent,
    MultidropdownComponent,
    SnackbarComponent,
    FontDropdownComponent,
    RangeComponent,
    LoginComponent,
    MultipleSelectionComponent,
    IGPHeaderComponent,
    SideNavComponent,
    CanvasComponent,
    PdfComponent,
    IGPComponent,
    SegmentComponent,
    SprinklerComponent,
    SuperAdminComponent,
    AuthorizeComponent,
    LogoutComponent,
    ExternalAccessComponent,
    TrainingLibraryComponent,
    ThreeDComponent,
    ThreeDViewerComponent,
    TopoPdfComponent,
    SegmentBookComponent,
    SprinklerBookComponent,
    LidarProcessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgbModule,
    LayoutModule,
    HttpClientModule,
    ProgressbarModule,
    NgxUiLoaderModule,
    StoreModule.forRoot(
      {
        clientsData: clientsReducer,
        showInactive: showInactiveReducer,
        userProfile: userProfileReducer,
        addUpdate: addUpdateReducer,
        addUpdateClient: addUpdateClientReducer,
        newUser: newUserReducer,
        userInfo: userInfoReducer,
        courseListData: courseListReducer
      }, { metaReducers }
    ),
  ],
  providers: [
    CookieService, HttpService,Title, SegmentBookService, ImageDataService, SprinklerBookService, ThreeDService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
