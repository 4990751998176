import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';



@NgModule({
  imports: [
    CommonModule,
    LayoutRoutingModule
  ],
  declarations: [
    LoginLayoutComponent
  ],
})
export class LayoutModule { }
