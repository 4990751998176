import { createReducer, on } from "@ngrx/store";
import { addUpdateAction, userDetails, userInfo } from "../actions/user.actions";
import { User } from "../models/user.model";

export const initialState: ReadonlyArray<User> = []
export const userInfoInitialState: any = []
export const addUpdateinitialState: boolean = false

export const userReducer = createReducer(
    initialState,
    on(userDetails, (state, { user }) => user)
)
export const addUpdateReducer = createReducer(
    addUpdateinitialState,
    on(addUpdateAction, (state, { addUpdate }) => addUpdate)
)
export const userInfoReducer = createReducer(
    userInfoInitialState,
    on(userInfo, (state, { userInfoDetails }) => userInfoDetails)
)