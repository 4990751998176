import { Injectable } from '@angular/core';
import { Color, GreenImageData } from './custom-types';

@Injectable({
    providedIn: 'root'
  })

export class ImageDataService {
    pixels: ImageData
    width: number
    height: number
    horizontalResolution: number
    verticalResolution: number

    top:number
    bottom:number
    right:number
    left:number
    

    greenImage(imageData: ImageData, locate:boolean = false) : GreenImageData {
        this.pixels = imageData
        this.width = imageData.width
        this.height = imageData.height
        // this.horizontalResolution = hRes
        // this.verticalResolution = vRes
        if(locate) {
            this.locateBounds()
        }
        return this.getGreenImageData()
    }

    /**
     * Segment Book only. Calculates points where green starts in the image(colored pixel)
     * - left, right
     * - top, bottom
     */
    locateBounds() {
        //find Bot
        let botY = 0
        let found = false
        for(let y = this.height-1; y > 0; y--){
            for(let x = 0; x < this.width; x++) {
                let isTransparent = (this.pixels.data[(y * (this.width * 4) + x * 4) + 3]) == 0;
                if(!isTransparent) {
                    botY = y
                    found = true
                    break
                }
            }
            if(found)
                break
        }
        this.bottom = botY

        //find Top
        let topY = 0
        found = false
        for(let y = 0; y < this.height; y++){
            for(let x = 0; x < this.width; x++) {
                let isTransparent = (this.pixels.data[(y * (this.width * 4) + x * 4) + 3]) == 0;
                if(!isTransparent) {
                    topY = y
                    found = true
                    break
                }
            }
            if(found)
                break
        }
        this.top = topY

        //find left
        let leftX = 0
        found = false
        for(let x = 0; x < this.width; x++){
            for(let y = 0; y < this.height; y++) {
                let isTransparent = (this.pixels.data[(y * (this.width * 4) + x * 4) + 3]) == 0;
                if(!isTransparent) {
                    leftX = x
                    found = true
                    break
                }
            }
            if(found)
                break
        }
        this.left = leftX

        //find right
        let rightX = 0
        found = false
        for(let x = this.width-1; x > 0; x--){
            for(let y = 0; y < this.height; y++) {
                let isTransparent = (this.pixels.data[(y * (this.width * 4) + x * 4) + 3]) == 0;
                if(!isTransparent) {
                    rightX = x
                    found = true
                    break
                }
            }
            if(found)
                break
        }
        this.right = rightX
    }

    getColor(imageData: GreenImageData, x: number, y: number) : Color {
        let i = (y * (imageData.width * 4) + x * 4)  // index of the pixel
        let color:Color = {
            R: imageData.pixels.data[i++],
            G: imageData.pixels.data[i++],
            B: imageData.pixels.data[i++],
            A: imageData.pixels.data[i++]
        }
        return color
    }

    getGreenImageData() : GreenImageData {
        let greenData: GreenImageData = {
            pixels: this.pixels,
            width: this.width,
            height: this.height,
            bottom: this.bottom,
            top: this.top,
            right: this.right,
            left: this.left
        }
        return greenData
    }

}