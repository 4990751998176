<mat-grid-list class="header-grid" cols="1">
    
    <div class="header-container">
        <div class="logo-frame">
            <img src="../../../assets/images/header-logo.svg">
        </div>
        <div class="logo-frame-2">
            <img src="../../../assets/images/header-logo.svg">
        </div>
        <div class="header-nav">
            <ul>
                <li class="show-alert" style="display: none;">
                    <div id="alert-models"></div>
                    <img [routerLink]="['/alerts']" width="17px" height="18px"
                        src="../../../../assets/images/notification.svg">
                </li>
                <li (click)="logOut()">LOG OUT</li>
            </ul>
        </div>
    </div>
</mat-grid-list>