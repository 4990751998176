import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ezlocator-admin-web';

  constructor ( private router: Router ) {

  }

  ngOnInit() {
    const bc = new BroadcastChannel("ezlo-admin");
    bc.onmessage = (event) => {
      bc.close()
      if(event.data == 'logout')
      {
        setTimeout(() => {
          this.router.navigate(['login'])
        }, 1000);
      }
    }
  }
}
