import { Component, OnInit } from '@angular/core';
import { selectUserInfo } from 'src/app/store/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/core/services/http/http.service';
import { CookieService } from 'ngx-cookie-service';
import { ThreeDService } from '../igp/three-d-service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isSuperAdminManager: any = false
  page: string = window.location.href.includes('igp') ? 
  'igp' : window.location.href.includes('client') ? 'client' : 'super-admin'
  userInfo$ = this.store.select(selectUserInfo)
  isNonSuperadmin: boolean = false;

  constructor(
    private store: Store,
    private httpService: HttpService,
    private cookieService: CookieService,
    private threeDService: ThreeDService
  ) {
      this.userInfo$.subscribe((data:any)=>{
        if(data) {
          this.isSuperAdminManager = data.IsSuperAdminManager
          if(data.RightsId)
            this.isNonSuperadmin = data.RightsId != 1
        }
      })
   }

  ngOnInit(): void {
    let refreshToken = this.cookieService.get('refreshToken')
    if(refreshToken)
      this.httpService.refreshExpirationCounter(this.httpService.getTokenExpiry(refreshToken)*1000)

    document.addEventListener("visibilitychange", () => {
      let refreshToken = this.cookieService.get('refreshToken')
      if(!document.hidden && this.httpService.getTokenExpiry(refreshToken) <= 0) {
        this.httpService.tokenExpiredCallback()
      }
    })
  }

  onPageChange(page: string) {
    this.page = page
    if(page == 'igp')
      this.threeDService.page(page)
  }
}
