import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showNotification: boolean = false;
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private http:HttpService
    // private store: Store
  ) { }

  logOut() {
    // let refreshToken = this.cookieService.get('refreshToken')
    let idToken = this.cookieService.get('idToken')
    if(idToken)
      window.location.href = `${environment.IDP_URL}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/logout`
    else 
      this.router.navigate(['logout'])
    // this.http.post(`/api/users/logout?refreshToken=${refreshToken}`, {})
    //   .subscribe((data) => {
    //     if (data.Status == 'Success') {
    //       this.cookieService.delete('showInactive')
    //       this.cookieService.delete('accessToken')
    //       this.cookieService.delete('clientId')
    //       this.cookieService.delete('courseId')
    //       this.cookieService.delete('selectedPinsheetDate')
    //       this.cookieService.delete('pinsheetDate')
    //       this.cookieService.delete('refreshToken')
    //       this.cookieService.delete('idToken')
    //       this.cookieService.delete('userId')
    //       this.cookieService.delete('loggedInUserId')
    //       // this.cookieService.deleteAll('/login','localhost')
    //       this.router.navigate(['logout'])
    //     }
    //   }
    // )
  }

  hideNotification() {
    this.showNotification = false
  }

  showResource(): void {
    this.showNotification = true
  }
  ngOnInit(): void {
    // this.checkUnread('/api/message/check/unread')
  }
  checkUnread(path: string) {
    // this.http.get(path)
    //   .subscribe((data) => {
    //     if (data.Status == 'Success') {
    //       if (data.Unread == false) {
    //         let element = document.getElementById('alert-models')
    //         if (element) {
    //           element.style.display = 'none'
    //         }
    //       }
    //       else {
    //         let element = document.getElementById('alert-models')
    //         if (element) {
    //           element.style.display = 'block'
    //         }
    //       }
    //     }
    //   }
    //   )
  }
}